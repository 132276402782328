import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';

import WithRender from './register-page.html';
import Password from 'vue-password-strength-meter';
import { ChangeCustomerDataWidget } from './../../../widgets/change-customer-data-widget';

import { MessagesBlock } from '@/components/snippets/messages';
import { AuthPlusPasswordStrengthMeter } from '@/components/snippets/auth-plus-password-strength-meter';

import * as Sentry from '@sentry/browser';

import IocContainer from '../../../../container/IocContainer';
import SERVICES from '../../../../container/Services';

import Auth from '../../../../interfaces/Auth';
import Tracking from '../../../../interfaces/Tracking';
import { Watch } from 'vue-property-decorator';

import VueRecaptcha from 'vue-recaptcha';
import { handleNavigationFailure } from '@/router/utils';
import Settings from '@/interfaces/Settings';

const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);
const settingsProvider = IocContainer.get<Settings>(SERVICES.SETTINGS);

@WithRender
@Component({
    components: {
        'change-customer-data-widget': ChangeCustomerDataWidget,
        'password-strength-meter': Password,
        'auth-plus-password-strength-meter': AuthPlusPasswordStrengthMeter,
        'messages-block': MessagesBlock,
        VueRecaptcha,
    },
})
export class RegisterPage extends Base {
    protected errorInformation: Record<any, any> = [];
    protected errors: {
        username: boolean;
        email: boolean;
        password: boolean;
        secretField: boolean;
    } = {
        username: false,
        email: false,
        password: false,
        secretField: false,
    };

    protected registrationStep1 = false;
    protected registrationStep2 = false;
    protected registrationStep3 = false;
    protected askUserForDataValidation = false;
    protected isLoading = false;

    protected showShouldVerifyMessage = false;

    protected elements = {
        bsw: [
            'input-with-label',
            'checkbox-accept-terms',
            'show-between',
            'password-strength-meter',
        ],
        qcells: ['show-between'],
        wdenergy: ['hide-register-customerId-input'],
        'wdenergy-us': ['hide-register-customerId-input'],
        velbert: ['input-background'],
        ele: ['input-background'],
        prokon: [
            'register-button-center',
            'already-have-account-below-registration',
        ],
        sachsenenergie: ['email-field', 'hide-register-customerId-input'],
        // wechselstrom: ['email-field', 'hide-register-customerId-input'],
        schweizstrom: [],
        elli: ['register-secondary-button', 'registration-step1-warning'],
        'dsp-*': ['auth-plus-password-strength-meter', 'auth-plus'],
        'sw-ahrensburg': ['auth-plus-password-strength-meter', 'auth-plus'],
    };

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);
    private details: {
        customerId: number | null;
        contractId: number | null;
        email: string | null;
        secretFieldType: string;
        secretFieldLabel: string;
        secretField: string;
        secretFieldConfirmed: string;
        secretFieldPrefix: string;
        secretFieldLength: number;
        newUsername: string;
        newUsernamePreset: boolean;
        newPassword: string;
        newPasswordConfirmation: string;
        termsAndConditions: boolean;
        alreadyAuthorized: boolean;
    } = {
        customerId: null,
        contractId: null,
        email: null,
        secretFieldType: '',
        secretFieldLabel: '',
        secretField: '',
        secretFieldConfirmed: '',
        secretFieldPrefix: '',
        secretFieldLength: 0,
        newUsername: '',
        newUsernamePreset: false,
        newPassword: '',
        newPasswordConfirmation: '',
        termsAndConditions: false,
        alreadyAuthorized: false,
    };

    get termsAndConditionslink(): string {
        switch (process.env.VUE_APP_ENVIRONMENT) {
            case 'bsw':
                return 'https://berlinerstadtwerke.de/kundenportal/nutzungsbedingungen/';
            default:
                return '';
        }
    }

    get showLoginAction(): boolean {
        return !this.$store.state.settings.forceUserToValidateData;
    }

    get secretFieldType(): string {
        return this.details.secretFieldType === 'zip' ? 'number' : 'text';
    }

    get registerButtonClass(): Record<any, any> {
        return {
            'flex-centered': this.displayElement('register-button-center'),
        };
    }

    get captchaEnabled(): boolean {
        return this.$store.state.settings.enableCaptchaForRegistration;
    }

    get showRegisterButtons(): boolean {
        if (!this.captchaEnabled || this.captchaSize === 'invisible') {
            return true;
        }

        return this.captchaPassed;
    }

    @Watch('details.contractId')
    public contractIdChanged(value: string): void {
        if (!parseInt(value, 10)) {
            this.details.contractId = null;
        }
    }

    @Watch('details.customerId')
    public customerIdChanged(value: string): void {
        if (!parseInt(value, 10)) {
            this.details.customerId = null;
        }
    }

    @Watch('details.newPassword')
    public sanitizePassword(value: string): void {
        if (value) {
            this.details.newPassword = value.replace(/\s/g, '');
        }
    }

    @Watch('details.newPasswordConfirmation')
    public sanitizePasswordConfirmation(value: string): void {
        if (value) {
            this.details.newPasswordConfirmation = value.replace(/\s/g, '');
        }
    }

    get isAuthPlusActive() {
        return this.$store.state.settings.isAuthPlusActive;
    }

    get isAuthPlusTwoFactorAuthenticationActive() {
        return this.$store.state.settings
            .isAuthPlusTwoFactorAuthenticationActive;
    }

    get showAuthPlusEmailWithLabel() {
        return this.isAuthPlusActive && this.displayElement('input-with-label');
    }

    get showAuthPlusEmailWithoutLabel() {
        return this.isAuthPlusActive;
    }

    @Watch('captchaPassed')
    public handleCaptchaPassed(value: boolean): void {
        if (this.captchaSize === 'invisible' && value) {
            this.getSecretField();
        }
    }

    public created(): void {
        this.$store.dispatch('auth/setStepFour', false);
        this.register();
    }

    public onVerify(response: string): void {
        if (response) {
            this.captchaToken = response;
            this.captchaPassed = true;
        }
    }

    public customerDataUpdated(): void {
        this.continueWithoutDataCheck();
    }

    public sanitizeUsername(): void {
        if (this.details.newUsername) {
            this.details.newUsername = this.details.newUsername?.replace(
                /\s/g,
                ''
            );
        }
    }

    private register(): void {
        this.registrationStep1 = true;
        trackingProvider.registerStep1Successful();

        if (
            this.$route.query.customerId &&
            typeof this.$route.query.customerId === 'string'
        ) {
            this.details.customerId = parseInt(
                this.$route.query.customerId,
                10
            );
            this.getSecretField(true);
        } else if (
            this.$route.query.contractId &&
            typeof this.$route.query.contractId === 'string'
        ) {
            this.details.contractId = parseInt(
                this.$route.query.contractId,
                10
            );
            this.getSecretField(true);
        }
    }

    private isNumeric(value): boolean {
        return /^-?\d+$/.test(value);
    }

    private getSecretField(skipCaptcha = false) {
        if (
            !skipCaptcha &&
            this.captchaEnabled &&
            this.$store.state.settings.invisibleCaptcha &&
            this.$refs.recaptcha
        ) {
            (this.$refs.recaptcha as VueRecaptcha).execute();
            return;
        }

        if (!skipCaptcha && this.captchaEnabled && !this.captchaPassed) {
            return;
        }

        this.clearErrors();

        if (
            this.details.contractId !== null &&
            !this.isNumeric(this.details.contractId)
        ) {
            this.errorInformation.push({
                key: '',
                message: this.$t(
                    'registration.contract.error.contractId'
                ).toLocaleString(),
            });
        }
        if (
            this.details.customerId !== null &&
            !this.isNumeric(this.details.customerId)
        ) {
            this.errorInformation.push({
                key: '',
                message: this.$t(
                    'registration.contract.error.customerId'
                ).toLocaleString(),
            });
        }
        if (this.errorInformation.length) {
            return;
        }

        if (
            this.details.customerId ||
            this.details.contractId ||
            this.details.email
        ) {
            this.isLoading = true;

            this.auth
                .getSecretField(
                    this.details.contractId,
                    this.details.customerId,
                    this.details.email,
                    this.captchaToken
                )
                .then(
                    (response) => {
                        this.isLoading = false;

                        if (response.data.success) {
                            if (
                                this.details.email &&
                                !this.details.customerId
                            ) {
                                this.details.customerId = parseInt(
                                    response.data.data.customerId,
                                    10
                                );
                            }
                            this.registrationStep1 = false;
                            this.registrationStep2 = true;
                            trackingProvider.registerStep2Successful();
                            this.details.secretFieldLabel =
                                response.data.data.secretFieldLabel;
                            this.details.secretFieldType =
                                response.data.data.secretFieldType;
                            this.details.secretFieldPrefix =
                                response.data.data.prefix;
                            this.details.secretFieldLength =
                                response.data.data.length;
                        } else {
                            trackingProvider.registerStep1Failed();
                            if (response.data.message) {
                                this.errorInformation.push({
                                    key: '',
                                    message: this.$t(
                                        response.data.message
                                    ).toLocaleString(),
                                });
                            } else {
                                this.errorInformation.push({
                                    key: '',
                                    message: this.$t(
                                        'registration.contract.error'
                                    ).toLocaleString(),
                                });
                            }
                        }
                    },
                    (error) => {
                        this.isLoading = false;

                        trackingProvider.registerStep1Failed();
                        this.errorInformation.push({
                            key: '',
                            message: this.$t(
                                'registration.contract.error'
                            ).toLocaleString(),
                        });
                        Sentry.captureException(new Error(error));
                    }
                );
        } else {
            this.errorInformation.push({
                key: '',
                message: this.$t('registration.noinput').toLocaleString(),
            });
        }
    }

    onSecretFieldIbanMaskedBlur(e: UIEvent): void {
        this.updateIbanMasked();
    }

    onSecretFieldIbanMaskedKeyUp(e: KeyboardEvent): void {
        this.updateIbanMasked();
        const elements = this.$el.querySelectorAll(
            '.secret-field-iban-masked:not(:read-only)'
        );
        const target: HTMLInputElement = e.target as HTMLInputElement;
        const currentIndex = Array.from(elements).indexOf(target);
        if (e.code != 'Backspace' && elements.length > currentIndex + 1) {
            (elements[currentIndex + 1] as HTMLInputElement).focus();
        }
    }

    private updateIbanMasked(): void {
        const ibanFields = this.$el.querySelectorAll(
            '.secret-field-iban-masked'
        );
        const values: string[] = [];
        for (let i = 0; i < ibanFields.length; ++i) {
            const inputElement: HTMLInputElement = ibanFields[
                i
            ] as HTMLInputElement;
            inputElement.value = inputElement.value.toUpperCase();
            values[i] = inputElement.value;
        }
        this.details.secretField = values.join('');
    }

    get showSecretFieldIbanMasked(): boolean {
        return (
            this.details.secretFieldType == 'ibanmasked' ||
            this.details.secretFieldType == 'ibanrandomdigit' ||
            this.details.secretFieldType == 'ibanhiddendigit'
        );
    }

    get secretFieldLength(): number {
        return this.details.secretFieldLength ?? 22;
    }

    get secretFieldPrefix(): string {
        return this.details.secretFieldPrefix ?? 'DE';
    }

    get secretFieldIbanMaskedFields(): Record<any, any>[] {
        const list: Record<any, any>[] = [];

        if (this.details.secretFieldType == 'ibanmasked') {
            for (let i = 0; i < this.secretFieldPrefix.length; ++i) {
                list.push({ value: this.secretFieldPrefix[i], readonly: true });
            }

            for (
                let i = 0;
                i < this.secretFieldLength - 4 - this.secretFieldPrefix.length;
                ++i
            ) {
                list.push({ value: 'X', readonly: true });
            }
            for (let i = 0; i < 4; ++i) {
                list.push({ value: '' });
            }
        } else if (this.details.secretFieldType == 'ibanrandomdigit') {
            const requestedLength: number = 4;

            const maxLength: number =
                this.secretFieldLength - 1 - this.secretFieldPrefix.length;
            for (let i = 0; i <= maxLength; ++i) {
                list.push({ value: 'X', readonly: true });
            }

            let randomNumber: number =
                (Math.random() * (list.length + 1 - requestedLength)) << 0;
            randomNumber =
                randomNumber > maxLength - requestedLength
                    ? maxLength - requestedLength
                    : randomNumber;

            for (let i = 0; i < requestedLength; ++i) {
                list[randomNumber + i] = {
                    value: '',
                    readonly: false,
                };
            }

            for (let i = this.secretFieldPrefix.length; i > 0; --i) {
                list.unshift({
                    value: this.secretFieldPrefix[i - 1],
                    readonly: true,
                });
            }
        } else if (this.details.secretFieldType == 'ibanhiddendigit') {
            const requestedLength: number =
                this.$store.state.settings.registrationIbanNumbersToCheck;
            const skipLength: number =
                this.$store.state.settings.registrationIbanNumbersToSkip;
            for (let i = 0; i < this.secretFieldPrefix.length; ++i) {
                list.push({ value: this.secretFieldPrefix[i], readonly: true });
            }
            const maxLength: number =
                this.secretFieldLength - 1 - this.secretFieldPrefix.length;
            for (let i = 0; i <= maxLength; ++i) {
                list.push({ value: 'X', readonly: true });
            }

            const firstLetter =
                maxLength + this.secretFieldPrefix.length - skipLength;
            for (let i = 0; i < requestedLength; i++) {
                list[firstLetter - i] = {
                    value: '',
                    readonly: false,
                };
            }
        }

        return list;
    }

    private checkSecretField() {
        this.clearErrors();

        if (this.details.secretField) {
            switch (this.details.secretFieldType) {
                case 'birthday':
                    if (
                        !this.details.secretField.match(
                            /^(0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[012]).\d{4}$/
                        )
                    ) {
                        this.errors.secretField = true;
                        this.errorInformation.push({
                            key: '',
                            message: this.$t(
                                'registration.secret.birthdate.error'
                            ).toLocaleString(),
                        });
                        return;
                    }
                    break;
                case 'bankaccount':
                case 'iban':
                    console.log('abc');
                    if (
                        !this.details.secretField.match(
                            /^[a-zA-Z]{2}[a-zA-Z\d]{3,32}$/
                        )
                    ) {
                        this.errors.secretField = true;
                        console.log('ja hier');
                        this.errorInformation.push({
                            key: '',
                            message: this.$t(
                                'registration.secret.iban.error'
                            ).toLocaleString(),
                        });
                        return;
                    }
                    break;
                case 'zip':
                    if (!this.details.secretField.match(/^\d{4,5}$/)) {
                        this.errors.secretField = true;
                        this.errorInformation.push({
                            key: '',
                            message: this.$t(
                                'registration.secret.zip.error'
                            ).toLocaleString(),
                        });
                        return;
                    }
                    break;
            }

            this.isLoading = true;

            this.auth
                .checkSecretField(
                    this.details.contractId,
                    this.details.customerId,
                    this.details.secretFieldType,
                    this.details.secretField
                )
                .then(
                    (response) => {
                        this.isLoading = false;

                        if (response.data.success) {
                            this.registrationStep2 = false;
                            this.registrationStep3 = true;
                            trackingProvider.registerStep3Successful();
                            this.details.secretFieldConfirmed =
                                response.data.data.secretField;
                        } else {
                            trackingProvider.registerStep2Failed();
                            this.errors.secretField = true;
                            if (response.data.message) {
                                this.errorInformation.push({
                                    key: '',
                                    message: response.data.message,
                                });
                            } else {
                                this.errorInformation.push({
                                    key: '',
                                    message: this.$t(
                                        'registration.secret.error'
                                    ).toLocaleString(),
                                });
                            }
                        }
                    },
                    (error) => {
                        this.isLoading = false;
                        Sentry.captureException(new Error(error));
                    }
                );
        } else {
            trackingProvider.registerStep2Failed();
            this.errors.secretField = true;
            this.errorInformation.push({
                key: '',
                message: this.$t(
                    'registration.secret.noinput'
                ).toLocaleString(),
            });
        }
    }

    private updateLogin() {
        this.isLoading = true;

        this.clearErrors();

        if (
            this.displayElement('checkbox-accept-terms') &&
            !this.details.termsAndConditions &&
            !this.details.alreadyAuthorized
        ) {
            this.errorInformation.push({
                key: '',
                message: this.$t(
                    'registration.accept.terms.submit'
                ).toLocaleString(),
            });
            this.isLoading = false;
            return false;
        }
        let customerIdUrl: string | null = null;
        if (
            this.$route.query.customerId &&
            !Array.isArray(this.$route.query.customerId)
        ) {
            customerIdUrl = this.$route.query.customerId;
        }

        if (this.currentClient === 'wechselstrom') {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(this.details.newUsername)) {
                this.errorInformation.push({
                    key: '',
                    message: this.$t(
                        'registration.username.email.invalid'
                    ).toLocaleString(),
                });
                this.isLoading = false;
                return false;
            }
        }

        if (this.details.secretField) {
            this.auth
                .updateLogin(
                    this.details.contractId,
                    this.details.customerId,
                    this.details.secretFieldConfirmed,
                    this.details.email,
                    this.details.newUsername,
                    this.details.newPassword,
                    this.details.newPasswordConfirmation,
                    customerIdUrl
                )
                .then(
                    (response) => {
                        this.isLoading = false;

                        if (response.data.success) {
                            this.registrationStep2 = false;
                            this.registrationStep3 = false;
                            trackingProvider.registerSuccessful();
                            this.auth.authenticated(response.data.authToken);
                            this.$store.dispatch('auth/set', true);
                            this.$store.dispatch('settings/load').then(() => {
                                // Adding a toggle to localStorage for making a request
                                // for Two Factor Authentication function enabling
                                if (
                                    this.isAuthPlusActive &&
                                    this.isAuthPlusTwoFactorAuthenticationActive
                                ) {
                                    localStorage.setItem(
                                        '2FA-enabling',
                                        'true'
                                    );
                                }

                                if (
                                    this.isAuthPlusActive &&
                                    response.data.requiredVerification
                                ) {
                                    this.showShouldVerifyMessage = true;
                                    return;
                                }

                                // redirect to data validation if required
                                if (
                                    response.data.redirectValidatePersonalData
                                ) {
                                    this.$store
                                        .dispatch('customer/getDetails')
                                        .then(
                                            () => {
                                                this.askUserForDataValidation =
                                                    true;
                                                this.$store.dispatch(
                                                    'auth/setStepFour',
                                                    true
                                                );
                                                trackingProvider.confirmDataAfterRegister();
                                            },
                                            () => {
                                                this.$router
                                                    .push('dashboard')
                                                    .catch((failure) =>
                                                        handleNavigationFailure(
                                                            failure
                                                        )
                                                    );
                                            }
                                        );
                                } else if (this.$route.name !== 'dashboard') {
                                    this.$router
                                        .push('dashboard')
                                        .catch((failure) =>
                                            handleNavigationFailure(failure)
                                        );
                                }
                            });
                        } else {
                            trackingProvider.registerStep3Failed();
                            let errorObject = response.data.messageLocalized;
                            if (typeof errorObject.message == 'object') {
                                errorObject = errorObject.message;
                            }
                            this.errorInformation.push(errorObject);
                        }
                    },
                    (error) => {
                        this.isLoading = false;

                        const errors =
                            typeof error.response.data !== 'undefined' &&
                            typeof error.response.data.errors !== 'undefined'
                                ? error.response.data.errors
                                : null;

                        if (errors) {
                            if (errors.username && errors.username.length > 0) {
                                this.errors.username = true;
                                this.setErrorsByResponse(errors.username);
                            }
                            if (errors.password && errors.password.length > 0) {
                                this.errors.password = true;
                                this.setErrorsByResponse(errors.password);
                            }
                            if (errors.email && errors.email.length > 0) {
                                this.errors.email = true;
                                this.setErrorsByResponse(errors.email);
                            }
                            if (
                                errors.contractId &&
                                errors.contractId.length > 0
                            ) {
                                this.setErrorsByResponse(errors.contractId);
                            }
                            if (
                                errors.customerId &&
                                errors.customerId.length > 0
                            ) {
                                this.setErrorsByResponse(errors.customerId);
                            }
                            if (
                                errors.secretField &&
                                errors.secretField.length > 0
                            ) {
                                this.errors.secretField = true;
                                this.setErrorsByResponse(errors.secretField);
                            }
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message: this.$t(
                                    'registration.data.error'
                                ).toLocaleString(),
                            });
                        }
                        Sentry.captureException(new Error(error));
                    }
                );
        } else {
            trackingProvider.registerStep3Failed();
            this.errorInformation.push({
                key: '',
                message: this.$t('registration.data.error').toLocaleString(),
            });
        }
    }

    private continueWithoutDataCheck() {
        this.$router
            .push('dashboard')
            .catch((failure) => handleNavigationFailure(failure));
    }

    private setErrorsByResponse(errors) {
        if (errors.length > 1) {
            const backet: string[] = [];
            for (const i in errors) {
                if (typeof errors[i] === 'string') {
                    backet.push(errors[i]);
                } else if (typeof errors[i] === 'object') {
                    backet.push(errors[i].message);
                }
            }
            this.errorInformation.push({
                key: '',
                message: backet.join('\r\n'),
            });
        } else {
            for (const i in errors) {
                if (typeof errors[i] === 'string') {
                    this.errorInformation.push({
                        key: '',
                        message: errors[i],
                    });
                } else if (typeof errors[i] === 'object') {
                    this.errorInformation.push({
                        key: errors[i].key,
                        message: errors[i].message,
                    });
                }
            }
        }
    }

    private clearErrors() {
        this.errors = {
            username: false,
            email: false,
            password: false,
            secretField: false,
        };
        this.errorInformation = [];
    }
}
