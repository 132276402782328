import Base from '@/mixins/base';
import WithRender from './one-time-password.html';
import { default as Component } from 'vue-class-component';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';
import { ShowMultipleErrorMessages } from '@/components/snippets/show-multiple-error-messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Auth from '@/interfaces/Auth';
import VueRecaptcha from 'vue-recaptcha';
import { Watch } from 'vue-property-decorator';

import VueButtonSpinner from 'vue-button-spinner';
import { handleNavigationFailure } from '@/router/utils';

@WithRender
@Component({
    props: {
        second: {
            type: Number,
            default: 0,
        },
    },
    components: {
        // External packages
        'messages-block': MessagesBlock,
        'vue-button-spinner': VueButtonSpinner,
        VueRecaptcha,
        'show-multiple-error-messages': ShowMultipleErrorMessages,
    },
})
export class OneTimePassword extends Base {
    public errorInformation: any[] = [];
    public successInformation: any[] = [];
    public passwordSent = false;
    public customerNumberContractNumberSent = false;

    protected isLoading: boolean = false;
    protected loginLoadingState: boolean = false;
    protected status: string = '';
    protected isTFAActive = false;
    protected showOtpAlternative = false;

    protected customerNumber: string = '';
    protected contractNumber: string = '';
    protected emailAddress: string = '';
    protected iban: string = '';
    protected otpAlternativeAuthTrait: string = '';
    protected otpAlternativeHint: string = '';
    protected otpAlternativePrefix: string = '';
    protected otpAlternativeLength: number = 0;

    protected code: string = '';
    protected password: string = '';
    protected errors: any[] = [];
    protected auth = IocContainer.get<Auth>(SERVICES.AUTH);
    protected secretFieldIbanMaskedFieldList: Record<any, any>[] = [];

    protected elements = {
        bsw: ['input-with-label', 'button-registration'],
        keppel: ['custom_forgot_links'],
        prokon: ['action-text-right', 'password-forgotten-bottom'],
        schweizstrom: ['show-register-link'],
        elli: [],
    };

    get username(): string {
        return this.$store.state.auth.username;
    }

    set username(username: string) {
        this.$store.dispatch('auth/setUserName', username);
    }

    get showMeterReadingLink(): string {
        return this.$store.state.settings.reportReadingWithoutLogin;
    }

    get isAuthPlusActive() {
        return this.$store.state.settings.isAuthPlusActive;
    }

    get isOneTimePasswordEnableAlternative(): boolean {
        return this.$store.state.settings
            .isAuthPlusOneTimePasswordEnabledAlternative;
    }

    get oneTimePasswordAlternativeIbanPromptedPositions(): [] {
        return this.$store.state.settings
            .authPlusOneTimePasswordIbanPromptedPositions;
    }

    get oneTimePasswordAlternativeIbanDisplayedPositions(): [] {
        return this.$store.state.settings
            .authPlusOneTimePasswordIbanDisplayedPositions;
    }

    get canSubmitOtpPrepare(): boolean {
        return this.username.length > 0;
    }

    get canSubmitOtpAlternative(): boolean {
        return this.customerNumber.length > 0 && this.contractNumber.length > 0;
    }

    get captchaEnabled(): boolean {
        return this.$store.state.settings.enableCaptchaForLogin;
    }

    get showLoginButton(): boolean {
        if (!this.captchaEnabled || this.captchaSize === 'invisible') {
            return true;
        }

        return this.captchaPassed;
    }

    @Watch('captchaPassed')
    public handleCaptchaPassed(value: boolean): void {
        if (this.captchaSize === 'invisible' && value) {
            this.processLogin();
        }
    }

    public onVerify(response: string): void {
        if (response) {
            this.captchaToken = response;
            this.captchaPassed = true;
        }
    }

    protected switchToOtpAlternative(): void {
        this.showOtpAlternative = true;
        this.username = '';
        this.passwordSent = false;
        this.errors = [];
        this.$emit('toggle', { is_active: true });
    }

    protected switchToOtpDefault(): void {
        this.showOtpAlternative = false;
        this.customerNumber = '';
        this.contractNumber = '';
        this.customerNumberContractNumberSent = false;
        this.errors = [];
        this.$emit('toggle', { is_active: false });
    }

    protected login(): void {
        if (
            this.captchaEnabled &&
            this.$store.state.settings.invisibleCaptcha &&
            this.$refs.recaptcha
        ) {
            (this.$refs.recaptcha as VueRecaptcha).execute();
            return;
        }

        if (this.captchaEnabled && !this.captchaPassed) {
            return;
        }

        this.processLogin();
    }

    protected trimPassword(): void {
        this.password = this.password.trim();
    }

    protected handleEnterKey(field: string): void {
        if (this.showOtpAlternative) {
            if (
                this.customerNumber &&
                this.customerNumber.length > 0 &&
                this.contractNumber &&
                this.contractNumber.length > 0
            ) {
                this.handleLoginOtpAlternative();
            }
        } else {
            if (field === 'username') {
                if (!this.passwordSent) {
                    this.processLogin();
                    return;
                }
            }

            this.loginWithOnetimePassword();
        }
    }

    protected processLogin(): void {
        if (this.showOtpAlternative) {
            this.handleLoginOtpAlternative();
        } else {
            this.handleLoginOtpDefault();
        }
    }

    protected handleLoginOtpAlternative(): void {
        if (this.customerNumberContractNumberSent) {
            this.isLoading = true;
            this.loginLoadingState = true;
            this.errors = [];
            //return;
            this.handleLoginOtpAlternativeFinalize();
        } else {
            this.isLoading = true;
            this.loginLoadingState = true;
            this.errors = [];
            this.handleLoginOtpAlternativePrepare();
        }
    }

    private handleLoginOtpAlternativeFinalize(): void {
        this.auth
            .onetimePasswordAlternative(
                this.customerNumber,
                this.contractNumber,
                this.emailAddress,
                this.iban,
                this.captchaToken
            )
            .then(
                (response) => {
                    this.isLoading = false;
                    this.loginLoadingState = false;
                    if (response && response.data && response.data.success) {
                        this.handleOtpLoginSuccess(response);
                    } else {
                        this.handleSpecificLoginErrorFromResponse(response);
                    }
                },
                (error) => {
                    this.handleGenericLoginError(error);
                }
            );
    }

    private handleLoginOtpAlternativePrepare(): void {
        this.auth
            .onetimePasswordAlternativePrepare(
                this.customerNumber,
                this.contractNumber,
                this.captchaToken
            )
            .then(
                (response) => {
                    this.isLoading = false;
                    this.loginLoadingState = false;
                    if (response && response.data && response.data.success) {
                        this.customerNumberContractNumberSent = true;
                        this.otpAlternativeAuthTrait = response.data.authTrait;
                        this.$emit('toggle', {
                            is_active: true,
                            trait: response.data.authTrait,
                        });
                        this.otpAlternativeHint = response.data.hint;
                        this.otpAlternativePrefix = response.data.prefix;
                        this.otpAlternativeLength = response.data.length;
                    } else {
                        this.handleSpecificLoginErrorFromResponse(response);
                    }
                },
                (error) => {
                    this.handleGenericLoginError(error);
                }
            );
    }

    protected handleLoginOtpDefault(): void {
        if (!this.loginLoadingState) {
            if (
                this.currentClient === 'velbert' ||
                this.currentClient === 'ele'
            ) {
                this.isLoading = true;
            }
            this.loginLoadingState = true;
            this.errors = [];
            this.auth
                .onetimePasswordPrepare(this.username, this.captchaToken)
                .then(
                    (response) => {
                        this.isLoading = false;
                        this.loginLoadingState = false;
                        if (
                            response &&
                            response.data &&
                            response.data.success
                        ) {
                            this.passwordSent = true;
                        } else {
                            this.handleSpecificLoginErrorFromResponse(response);
                        }
                    },
                    (error) => {
                        this.handleGenericLoginError(error);
                    }
                );
        }
    }

    protected get isOneTimePasswordLoginReady(): boolean {
        if (this.password && this.password.length > 0) {
            return true;
        }
        return false;
    }

    protected loginWithOnetimePassword() {
        if (!this.isOneTimePasswordLoginReady) {
            return false;
        }

        this.errorInformation = [];
        this.successInformation = [];
        this.loginLoadingState = true;
        this.errors = [];
        this.auth
            .onetimePasswordLogin(this.username, this.password)
            .then((response) => {
                if (response && response.data && response.data.success) {
                    this.handleOtpLoginSuccess(response);
                } else {
                    this.handleSpecificOtpLoginErrorFromResponse(response);
                }
            })
            .catch((error) => {
                this.handleGenericOtpLoginError(error);
            });
    }

    private handleGenericOtpLoginError(error): void {
        this.loginLoadingState = false;
        this.$store.dispatch('auth/incrementErrorTry');
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            Object.keys(error.response.data.errors).length > 0
        ) {
            this.errors = Object.values(error.response.data.errors);
        } else {
            this.errors.push([this.$t('login.user.error')]);
            Sentry.captureException(new Error(error));
        }
    }

    private handleSpecificOtpLoginErrorFromResponse(response): void {
        this.loginLoadingState = false;
        this.errors.push([this.$t('login.user.error')]);
        this.$store.dispatch('auth/incrementErrorTry');

        if (typeof response.data.messageLocalized === 'object') {
            this.errorInformation.push(response.data.messageLocalized);
        } else if (typeof response.data.messageLocalized === 'string') {
            this.errorInformation.push({
                key: '',
                message: response.data.messageLocalized,
            });
        } else {
            this.errorInformation.push({
                key: '',
                message: this.$t('general.error').toLocaleString(),
            });
        }
    }

    private handleOtpLoginSuccess(response): void {
        if (
            typeof response.data.authToken !== 'undefined' &&
            response.data.authToken
        ) {
            this.auth.authenticated(response.data.authToken);
            this.$store.dispatch('auth/set', true);
            this.$store.dispatch('auth/errorTryReset');

            if (
                this.currentClient === 'velbert' ||
                this.currentClient === 'ele'
            ) {
                this.handleOtpLoginSuccessVelbert(response);
            } else {
                this.handleOtpLoginSuccessDefault(response);
            }
        }
    }

    private handleOtpLoginSuccessVelbert(response): void {
        this.$store.dispatch('contracts/contracts').then(() => {
            this.$store
                .dispatch(
                    'contracts/setContractId',
                    parseInt(this.contractsList[0].contractId, 10)
                )
                .then(() => {
                    this.$router
                        .push({
                            name: 'dashboardId',
                            params: {
                                id: this.contractsList[0].contractId,
                            },
                        })
                        .catch((failure) => handleNavigationFailure(failure));
                });
        });
    }

    private handleOtpLoginSuccessDefault(response): void {
        if (response.data.contractId) {
            this.$router
                .push({
                    name: 'dashboardId',
                    params: {
                        id: response.data.contractId,
                    },
                })
                .catch((failure) => handleNavigationFailure(failure));
        } else {
            this.$router
                .push({ name: 'dashboard' })
                .catch((failure) => handleNavigationFailure(failure));
        }
    }

    private handleSpecificLoginErrorFromResponse(response): void {
        if (this.currentClient === 'velbert' || this.currentClient === 'ele') {
            this.errors.push([response.data.response.messageLocalized]);
        } else {
            if (
                response &&
                response.data &&
                response.data.message &&
                response.data.message.key
            ) {
                this.errors.push([this.$t(response.data.message.key)]);
            } else {
                this.errors.push([this.$t('login.otp.alternative.error')]);
            }
        }
        this.$store.dispatch('auth/incrementErrorTry');
    }

    private handleGenericLoginError(error): void {
        this.isLoading = false;
        this.loginLoadingState = false;
        this.$store.dispatch('auth/incrementErrorTry');
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            Object.keys(error.response.data.errors).length > 0
        ) {
            this.errors = Object.values(error.response.data.errors);
        } else {
            this.errors.push([this.$t('login.onetime.password.error')]);
            Sentry.captureException(new Error(error));
        }
    }

    protected getRandomInt(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    get secretFieldIbanMaskedFields(): Record<any, any>[] {
        const list = this.secretFieldIbanMaskedFieldList;

        if (list && list.length > 0) {
            for (let i = 0; i < list.length; ++i) {
                const existingValue = this.iban.substr(i, 1);
                if (!list[i].readonly && existingValue != 'X') {
                    list[i].value = existingValue;
                }
            }
            return list;
        }

        for (
            let i = 0;
            i < this.otpAlternativeLength - this.otpAlternativePrefix.length;
            ++i
        ) {
            list.push({ value: 'X', readonly: true });
        }

        if (this.oneTimePasswordAlternativeIbanPromptedPositions.length == 0) {
            // Pick random fields
            const randomNumber: number = this.getRandomInt(
                this.otpAlternativePrefix.length,
                this.otpAlternativeLength - 4
            );
            for (let i = 0; i < 4; ++i) {
                list[randomNumber + i].value = this.iban.substr(
                    randomNumber + i,
                    1
                );
                list[randomNumber + i].readonly = false;
            }
        }

        for (let i = this.otpAlternativePrefix.length - 1; i >= 0; --i) {
            list.unshift({
                value: this.otpAlternativePrefix.substring(i, i + 1),
                readonly: true,
            });
        }

        if (this.oneTimePasswordAlternativeIbanPromptedPositions.length > 0) {
            // Prompt for fixed fields (configured in the settings)
            for (
                let i = 0;
                i < this.oneTimePasswordAlternativeIbanPromptedPositions.length;
                ++i
            ) {
                const position: number =
                    this.oneTimePasswordAlternativeIbanPromptedPositions[i] - 1;
                list[position].value = this.iban.substr(position, 1);
                list[position].readonly = false;
            }
        }

        if (this.oneTimePasswordAlternativeIbanDisplayedPositions.length > 0) {
            // Display certain fields (configured in the settings)
            list[0].value = 'X';
            list[1].value = 'X';
            for (
                let i = 0;
                i <
                this.oneTimePasswordAlternativeIbanDisplayedPositions.length;
                ++i
            ) {
                const position: number =
                    this.oneTimePasswordAlternativeIbanDisplayedPositions[i] -
                    1;
                list[position].value = this.otpAlternativeHint.substr(i, 1);
            }
        }

        return list;
    }

    onSecretFieldIbanMaskedKeyUp(e: KeyboardEvent): void {
        const elements = this.$el.querySelectorAll(
            '.secret-field-iban-masked:not(:read-only)'
        );
        const target: HTMLInputElement = e.target as HTMLInputElement;
        const currentIndex = Array.from(elements).indexOf(target);

        if (e.code == 'Enter') {
            this.handleEnterKey('otpAlternativeIban');
            return;
        } else if (e.code == 'Backspace' || e.code == 'ArrowLeft') {
            if (currentIndex > 0) {
                (elements[currentIndex - 1] as HTMLInputElement).select();
            }
        } else if (elements.length > currentIndex + 1) {
            (elements[currentIndex + 1] as HTMLInputElement).select();
        }
        this.updateIbanMasked();

        e.preventDefault();
        e.stopImmediatePropagation();
    }

    private updateIbanMasked(): void {
        const ibanFields = this.$el.querySelectorAll(
            '.secret-field-iban-masked'
        );
        const values: string[] = [];
        for (let i = 0; i < ibanFields.length; ++i) {
            const inputElement: HTMLInputElement = ibanFields[
                i
            ] as HTMLInputElement;
            inputElement.value = inputElement.value.toUpperCase();
            values[i] =
                inputElement.value && inputElement.value.length > 0
                    ? inputElement.value
                    : 'X';
            this.secretFieldIbanMaskedFieldList[i].value =
                inputElement.value && inputElement.value.length > 0
                    ? '' + inputElement.value
                    : '';
        }

        this.iban = values.join('');
    }
}
