import Base from '@/mixins/base';
import WithRender from './login-page.html';
import { default as Component } from 'vue-class-component';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';
import { ShowMultipleErrorMessages } from '@/components/snippets/show-multiple-error-messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Auth from '@/interfaces/Auth';
import VueRecaptcha from 'vue-recaptcha';
import { Watch } from 'vue-property-decorator';
import VueButtonSpinner from 'vue-button-spinner';

import { AuthPlusNewPasswordWidget } from '@/components/widgets/auth-plus-new-password-widget';
import { handleNavigationFailure } from '@/router/utils';

@WithRender
@Component({
    props: {
        second: {
            type: Number,
            default: 0,
        },
    },
    components: {
        // External packages
        'messages-block': MessagesBlock,
        'show-multiple-error-messages': ShowMultipleErrorMessages,
        'vue-button-spinner': VueButtonSpinner,
        VueRecaptcha,
        AuthPlusNewPasswordWidget,
    },
})
export class LoginPage extends Base {
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];

    protected isLoading = false;
    protected status = '';
    protected loginLoadingState = false;
    protected isTFAActive = false;
    protected failedAttempts = 0;
    protected authPlusShowCaptcha = false;
    protected errors: any[] = [];
    protected elements = {
        bsw: ['input-with-label', 'button-registration', 'separated-sub-links'],
        keppel: ['custom_forgot_links'],
        prokon: [
            'action-text-right',
            'password-forgotten-bottom',
            'separated-sub-links',
        ],
        schweizstrom: ['show-register-link'],
        elli: [],
    };

    private code = '';
    private password = '';
    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    get username(): string {
        return this.$store.state.auth.username;
    }

    set username(username: string) {
        this.$store.dispatch('auth/setUserName', username.replace(/\s/g, ''));
    }

    get showMeterReadingLink(): string {
        return this.$store.state.settings.reportReadingWithoutLogin;
    }

    get isAuthPlusActive() {
        return this.$store.state.settings.isAuthPlusActive;
    }

    get captchaEnabled(): boolean {
        return (
            this.$store.state.settings.enableCaptchaForLogin ||
            this.authPlusShowCaptcha
        );
    }

    get showLoginButton(): boolean {
        if (!this.captchaEnabled || this.captchaSize === 'invisible') {
            return true;
        }

        return this.captchaPassed;
    }

    beforeMount() {
        this.$cookies.remove('SUPER-ADMIN-TOKEN');
    }

    @Watch('captchaPassed')
    public handleCaptchaPassed(value: boolean): void {
        if (this.captchaSize === 'invisible' && value) {
            this.processLogin();
        }
    }

    public onVerify(response: string): void {
        if (response) {
            this.captchaToken = response;
            this.captchaPassed = true;
        }
    }

    private login(): void {
        if (
            this.captchaEnabled &&
            this.$store.state.settings.invisibleCaptcha &&
            this.$refs.recaptcha
        ) {
            (this.$refs.recaptcha as VueRecaptcha).execute();
            return;
        }

        if (this.captchaEnabled && !this.captchaPassed) {
            return;
        }

        this.processLogin();
    }

    private processLogin(): void {
        if (!this.loginLoadingState) {
            if (
                this.currentClient === 'velbert' ||
                this.currentClient === 'ele'
            ) {
                this.isLoading = true;
            }
            this.loginLoadingState = true;
            this.errors = [];
            this.auth
                .login(
                    this.username,
                    this.password.replace(/\s/g, ''),
                    this.captchaToken
                )
                .then(
                    (response) => {
                        this.onLoginSuccess(response);
                    },
                    (error) => {
                        this.onLoginError(error);
                    }
                );
        }
    }

    protected onLoginSuccess(response) {
        this.handleLoginSuccess(response);
    }

    protected handleLoginSuccess(response) {
        if (response && response.data && response.data.success) {
            if (
                typeof response.data.authToken !== 'undefined' &&
                response.data.authToken
            ) {
                this.auth.authenticated(response.data.authToken);
                this.$store.dispatch('auth/set', true);
                this.$store.dispatch('auth/errorTryReset');

                if (
                    this.currentClient === 'velbert' ||
                    this.currentClient === 'ele'
                ) {
                    this.$store.dispatch('contracts/contracts').then(() => {
                        this.isLoading = false;
                        this.$store
                            .dispatch(
                                'contracts/setContractId',
                                parseInt(this.contractsList[0].contractId, 10)
                            )
                            .then(() => {
                                this.$router
                                    .push({
                                        name: 'dashboardId',
                                        params: {
                                            id: this.contractsList[0]
                                                .contractId,
                                        },
                                    })
                                    .catch((failure) =>
                                        handleNavigationFailure(failure)
                                    );
                            });
                    });
                } else {
                    this.isLoading = false;
                    this.$router
                        .push({ name: 'dashboard' })
                        .catch((failure) => handleNavigationFailure(failure));
                }
            } else if (
                typeof response.data.isTFAActive !== 'undefined' &&
                response.data.isTFAActive
            ) {
                // If Two Factor Authentication is enabled on user's side
                this.isTFAActive = response.data.isTFAActive;
                this.loginLoadingState = false;
            }
        } else {
            this.onInvalidAuth(response);
        }
    }

    protected onLoginError(error) {
        this.handleLoginError(error);
    }

    protected handleLoginError(error) {
        ++this.failedAttempts;
        if (
            this.$store.state.settings.authPlusEnableRecaptcha &&
            this.failedAttempts >=
                this.$store.state.settings.authPlusAttemptsForRecaptcha
        ) {
            this.authPlusShowCaptcha = true;
        }
        this.isLoading = false;
        this.loginLoadingState = false;
        this.$store.dispatch('auth/incrementErrorTry');
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            Object.keys(error.response.data.errors).length > 0
        ) {
            this.errors = Object.values(error.response.data.errors);
        } else {
            this.errors.push([this.$t('login.user.error')]);
            Sentry.captureException(new Error(error));
        }
    }

    protected onInvalidAuth(response) {
        this.handleInvalidAuth(response);
    }

    protected handleInvalidAuth(response) {
        this.isLoading = false;
        this.loginLoadingState = false;
        if (response.data.forceChangePassword) {
            if (response.data.isTFAActive) {
                this.$store.dispatch(
                    'auth/isTFAActive',
                    response.data.isTFAActive
                );
            }
            this.$store.dispatch('auth/forceChangePassword', this.username);
            return;
        }

        if (this.currentClient === 'velbert' || this.currentClient === 'ele') {
            this.errors.push([response.data.response.messageLocalized]);
        } else {
            this.errors.push([this.$t('login.user.error')]);
        }
        this.$store.dispatch('auth/incrementErrorTry');
    }

    private loginWithTFA() {
        this.errorInformation = [];
        this.successInformation = [];
        this.loginLoadingState = true;
        this.errors = [];
        this.auth
            .twoFactorAuthenticationCheckCode({
                username: this.username,
                code: this.code,
            })
            .then((response) => {
                if (response && response.data && response.data.success) {
                    if (
                        typeof response.data.authToken !== 'undefined' &&
                        response.data.authToken
                    ) {
                        this.auth.authenticated(response.data.authToken);
                        this.$store.dispatch('auth/set', true);
                        this.$store.dispatch('auth/errorTryReset');

                        if (
                            this.currentClient === 'velbert' ||
                            this.currentClient === 'ele'
                        ) {
                            this.$store
                                .dispatch('contracts/contracts')
                                .then(() => {
                                    this.$store
                                        .dispatch(
                                            'contracts/setContractId',
                                            parseInt(
                                                this.contractsList[0]
                                                    .contractId,
                                                10
                                            )
                                        )
                                        .then(() => {
                                            this.$router
                                                .push({
                                                    name: 'dashboardId',
                                                    params: {
                                                        id: this
                                                            .contractsList[0]
                                                            .contractId,
                                                    },
                                                })
                                                .catch((failure) =>
                                                    handleNavigationFailure(
                                                        failure
                                                    )
                                                );
                                        });
                                });
                        } else {
                            this.$router
                                .push({ name: 'dashboard' })
                                .catch((failure) =>
                                    handleNavigationFailure(failure)
                                );
                        }
                    }
                } else {
                    this.loginLoadingState = false;
                    this.errors.push([this.$t('login.user.error')]);
                    this.$store.dispatch('auth/incrementErrorTry');

                    if (typeof response.data.messageLocalized === 'object') {
                        this.errorInformation.push(
                            response.data.messageLocalized
                        );
                    } else if (
                        typeof response.data.messageLocalized === 'string'
                    ) {
                        this.errorInformation.push({
                            key: '',
                            message: response.data.messageLocalized,
                        });
                    } else {
                        this.errorInformation.push({
                            key: '',
                            message: this.$t('general.error').toLocaleString(),
                        });
                    }
                }
            })
            .catch((error) => {
                this.loginLoadingState = false;
                this.$store.dispatch('auth/incrementErrorTry');
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.errors &&
                    Object.keys(error.response.data.errors).length > 0
                ) {
                    this.errors = Object.values(error.response.data.errors);
                } else {
                    this.errors.push([this.$t('login.user.error')]);
                    Sentry.captureException(new Error(error));
                }
            });
    }
}
