import { default as Component } from 'vue-class-component';
import WithRender from './change-billing-data-widget.html';
import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import Auth from '../../../interfaces/Auth';
import Tariff from '../../../interfaces/Tariff';
import SubmitBilling from '../../../interfaces/SubmitBilling';
import Map from '@/interfaces/Map';
import vSelect from 'vue-select';
import { Watch } from 'vue-property-decorator';
import City from '@/interfaces/City';
import Tracking from '@/interfaces/Tracking';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import Base from '@/mixins/base';

import { FormComponents } from '@/components/snippets/form-components';
import Contracts from '@/interfaces/Contracts';

const mapProvider = IocContainer.get<Map>(SERVICES.MAP);
const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const tariffProvider = IocContainer.get<Tariff>(SERVICES.TARIFF);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);
const contractProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);

@WithRender
@Component({
    components: {
        'v-select': vSelect,
        'messages-block': MessagesBlock,
        'custom-input': FormComponents.CustomInput,
        'custom-label': FormComponents.CustomLabel,
        'form-group': FormComponents.FormGroup,
        'form-wrapper': FormComponents.FormWrapper,
        'custom-datetime': FormComponents.CustomDateTime,
        'custom-radio': FormComponents.CustomRadio,
        'custom-checkbox': FormComponents.CustomCheckbox,
        'custom-v-select': FormComponents.CustomVSelect,
    },
})
export class ChangeBillingDataWidget extends Base {
    public submitPending = false;
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];

    public cities: City[] = [];
    public streets: [] = [];
    public selectedCityId: null | number = null;
    public selectedCityZip = null;
    public selectedCityName = null;
    public selectedStreet = null;
    public selectedSalutation: null | string = null;
    public streetError = false;
    public cityError = false;
    public houseNumberError = false;
    public showPostboxField = false;

    public customerCommunicationProfileError = false;
    public selectedCommunicationProfile: string | null = null;
    public originalCommunicationProfile: string | null = null;

    public zipFailed = false;

    protected details: SubmitBilling & {
        communicationProfile?: string | null;
    } = {
        contractId: this.$store.state.contracts.contractId,
        billingSalutation: null,
        billingCompany: null,
        billingFirstName: null,
        billingSurname: null,
        billingStreet: null,
        billingHouseNumber: null,
        billingCity: null,
        billingZip: null,
        billingPhoneMobile: null,
        billingCareOfRecipient: null,
        billingEmail: null,
        synchronizeWithCustomer: false,
        postbox: null,
        vatId: null,
        taxNumber: null,
        communicationProfile: null,
    };

    protected salutationOptionsCompanyFamily: {
        key: number;
        label: TranslateResult;
    }[] = [
        { key: 8, label: this.$t('widget.cbd.company') },
        { key: 3, label: this.$t('widget.cbd.family') },
    ];

    protected salutationOptions: { key: number; label: TranslateResult }[] = [
        { key: 9, label: this.$t('widget.cbd.without') },
        { key: 1, label: this.$t('widget.cbd.mr') },
        { key: 2, label: this.$t('widget.cbd.mrs') },
        ...(!this.displayElement('hide-company-family-dears')
            ? this.salutationOptionsCompanyFamily
            : []),
    ];

    private currentZipInFilter: any = null;

    protected elements = {
        bsw: ['edit-icon', 'show-communication-profile'],
        wdenergy: ['email*', 'hide-company-family-dears'],
        'wdenergy-us': ['email*', 'hide-company-family-dears'],
        sachsenenergie: ['show-personal-info-link'],
        velbert: [
            'limit-zip-to-numbers',
            'postbox-checkbox-enabled',
            'require-five-digits-zip',
        ],
        ele: [
            'limit-zip-to-numbers',
            'postbox-checkbox-enabled',
            'require-five-digits-zip',
        ],
    };

    public checkboxOptionsPostbox() {
        return [
            {
                value: true,
                label: this.$t('widget.cbd.postbox.checkbox').toLocaleString(),
            },
        ];
    }

    public checkboxOptionsAcceptAsCustomer() {
        return [
            {
                value: true,
                label: this.$t('widget.cbd.acceptAsCustomer').toLocaleString(),
            },
        ];
    }

    get billingAddress(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.billingAddress;
    }

    get isPostboxEnabled(): boolean {
        return this.$store.state.settings.customerPostboxEnabled;
    }

    get isShowPostboxField(): boolean {
        return (
            this.isPostboxEnabled &&
            (this.displayElement('postbox-checkbox-enabled')
                ? this.showPostboxField
                : false)
        );
    }

    get isPostboxError(): boolean {
        return (
            this.details.postbox !== null &&
            this.details.postbox !== '' &&
            (!/^[\d\s]+$/.test(this.details.postbox) ||
                !(this.details.postbox.length <= 10))
        );
    }

    get communicationProfilesOptions(): { value: string; label: string }[] {
        return this.$store.state.customer.communicationProfiles ?? [];
    }

    @Watch('selectedCityZip')
    protected onZipChange(newVal): void {
        if (newVal !== this.billingAddress.zip) {
            this.details.billingStreet = '';
            this.details.billingHouseNumber = '';
        }
    }

    protected communicationProfileInput(value): void {
        if (value && value.value) {
            this.customerCommunicationProfileError = false;
            this.details.communicationProfile = value.value;
            this.selectedCommunicationProfile = value.label;
        }
    }

    protected async mounted() {
        trackingProvider.showMasterDataChangeInvoiceAddress();

        if (this.displayElement('show-communication-profile')) {
            const result = await contractProvider.getCommunicationProfile(
                this.contractId ?? 0
            );
            if (result.data.success) {
                this.details.communicationProfile = result.data.data;
                this.originalCommunicationProfile = result.data.data;
                this.selectedCommunicationProfile =
                    this.communicationProfilesOptions.find(
                        (option) =>
                            option.value === this.details.communicationProfile
                    )?.label ?? '';
            }
        }

        if (this.showSelectBillingAddress) {
            await this.citySearch(
                `${this.billingAddress.zip} ${this.billingAddress.city}`,
                'text'
            );

            this.selectedCityZip = this.billingAddress.zip;
            this.selectedCityName = this.billingAddress.city;

            const selectedCity = this.cities.find((city) => {
                return (
                    city.zip === this.billingAddress.zip &&
                    city.name === this.billingAddress.city
                );
            });

            this.selectedStreet = this.billingAddress.street;

            if (selectedCity) {
                try {
                    const response = await mapProvider.streets(
                        this.contract.energy,
                        parseInt(selectedCity.id, 10)
                    );
                    this.streets = response.data;
                } catch (error) {
                    Sentry.captureException(new Error(error));
                }
            }
        }

        if (this.contract.energy === 'non_energy') {
            this.$store
                .dispatch('tariff/details', parseInt(this.contract.id, 10))
                .then(() => {
                    this.mapData();
                });
        }

        this.details.contractId = this.$store.state.contracts.contractId;
        this.mapData();
    }

    private mapData() {
        this.details.billingSalutation = this.billingAddress.salutation;
        this.details.billingCompany = this.billingAddress.company;
        this.details.billingFirstName = this.billingAddress.firstname;
        this.details.billingSurname = this.billingAddress.surname;
        this.details.billingStreet = this.billingAddress.street;
        this.details.billingHouseNumber = this.billingAddress.houseNumber;
        this.details.billingCity = this.billingAddress.city;
        this.details.billingZip = this.billingAddress.zip;
        this.details.billingPhoneMobile = this.billingAddress.phone;
        this.details.billingEmail = this.billingAddress.email;
        this.details.billingCareOfRecipient =
            this.billingAddress.careOfRecipient;
        this.details.postbox = this.billingAddress.postbox;
        this.showPostboxField =
            this.isPostboxEnabled &&
            this.billingAddress.postbox !== '' &&
            this.billingAddress.postbox !== null;

        this.details.taxNumber = this.contract.taxNumber;
        this.details.vatId = this.contract.vatId;

        let selectedSalutationObject = this.salutationOptions[9];
        for (const salutationOption of this.salutationOptions) {
            if (
                salutationOption &&
                salutationOption.key ===
                    parseInt(this.details.billingSalutation, 10)
            ) {
                selectedSalutationObject = salutationOption;
                break;
            }
        }
        this.selectedSalutation =
            selectedSalutationObject && selectedSalutationObject.label
                ? selectedSalutationObject.label.toString()
                : '';
    }

    protected destroyed(): void {
        trackingProvider.hideMasterDataChangeInvoiceAddress();
    }

    protected salutationInput(value): void {
        if (value) {
            this.details.billingSalutation = value.key ? value.key : value;
            this.selectedSalutation = value.label ? value.label : value;
        } else {
            this.selectedSalutation = this.details.billingSalutation = null;
        }
    }

    get submitButtonDisabled(): boolean {
        return (
            !this.details.billingZip ||
            !this.details.billingCity ||
            !this.details.billingStreet ||
            !this.details.billingHouseNumber ||
            this.submitPending ||
            !this.dataChanged
        );
    }

    get dataChanged(): boolean {
        return (
            this.details.contractId !==
                this.$store.state.contracts.contractId ||
            this.details.billingSalutation !== this.billingAddress.salutation ||
            this.details.billingCompany !== this.billingAddress.company ||
            this.details.billingFirstName !== this.billingAddress.firstname ||
            this.details.billingSurname !== this.billingAddress.surname ||
            this.details.billingStreet !== this.billingAddress.street ||
            this.details.billingHouseNumber !==
                this.billingAddress.houseNumber ||
            this.details.billingCity !== this.billingAddress.city ||
            this.details.billingZip !== this.billingAddress.zip ||
            this.details.billingPhoneMobile !== this.billingAddress.phone ||
            this.details.billingEmail !== this.billingAddress.email ||
            this.details.billingCareOfRecipient !==
                this.billingAddress.careOfRecipient ||
            this.details.postbox !== this.billingAddress.postbox ||
            this.details.taxNumber !== this.contract.taxNumber ||
            this.details.vatId !== this.contract.vatId ||
            this.details.communicationProfile !==
                this.originalCommunicationProfile
        );
    }

    get showSelectBillingAddress(): boolean {
        return this.$store.state.settings.validateBillingAddressEnetActive;
    }

    get contract(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract;
    }

    @Watch('details.billingZip')
    protected onMatchZip(value): void {
        if (this.displayElement('limit-zip-to-numbers')) {
            this.details.billingZip = value.replace(/[^\d]/, '');
        }
    }

    protected async citySearch(search, searchType = 'zip'): Promise<void> {
        if (search.length >= 3) {
            try {
                const response = await mapProvider.cities(
                    this.contract.energy,
                    search.replace(/^0+/, '')
                );

                this.cities = this.citiesNormalizing(
                    response.data.results,
                    searchType
                ).filter((city) => {
                    if (searchType === 'zip') {
                        return /^-{0,1}\d+$/.test(search)
                            ? new RegExp('^' + search).test(city.zip)
                            : false;
                    } else if (searchType === 'name') {
                        return new RegExp('^' + search, 'i').test(city.name);
                    } else if (searchType === 'text') {
                        return new RegExp('^' + search, 'i').test(city.text);
                    }
                    return false;
                });
            } catch (error) {
                Sentry.captureException(new Error(error));
            }
        } else {
            this.cities = [];
        }
    }

    protected citiesNormalizing(filteredCities, searchType): Record<any, any> {
        const citiesLength = filteredCities.length;

        let cities = filteredCities.map((city) => {
            const cityNormalized = { ...city };
            if (cityNormalized.zip.length < 5) {
                cityNormalized.text = '0' + city.text;
                cityNormalized.zip = '0' + city.zip;
            }
            return cityNormalized;
        });

        if (searchType === 'zip') {
            let currentZipInFilter = null;
            const filteredByZip = cities.every((city) => {
                if (currentZipInFilter === null) {
                    currentZipInFilter = city.zip;
                    return true;
                }
                return currentZipInFilter === city.zip;
            });

            cities = cities.map((city) => {
                const cityNormalized = { ...city };
                cityNormalized.zipText =
                    filteredByZip && citiesLength > 1
                        ? `${cityNormalized.zip} ${cityNormalized.name}`
                        : cityNormalized.zip;
                return cityNormalized;
            });
        } else {
            const nameDuplicatesCount = cities.reduce((prev, cur) => {
                prev[cur.name] = (prev[cur.name] || 0) + 1;
                return prev;
            }, {});

            cities = cities.map((city) => {
                const cityNormalized = { ...city };
                cityNormalized.nameText =
                    nameDuplicatesCount[cityNormalized.name] &&
                    nameDuplicatesCount[cityNormalized.name] > 1
                        ? `${cityNormalized.name} ${cityNormalized.zip}`
                        : cityNormalized.name;
                return cityNormalized;
            });
        }

        return cities;
    }

    protected cityInput(value): void {
        if (value) {
            this.selectedCityId = value.id;
            this.selectedCityZip = this.details.billingZip = value.zip;
            this.selectedCityName = this.details.billingCity = value.name;
            this.cityError = false;

            if (this.selectedCityId) {
                this.selectedStreet = this.details.billingStreet = null;
                mapProvider
                    .streets(this.contract.energy, this.selectedCityId)
                    .then(
                        (response) => {
                            this.streets = response.data;
                        },
                        (error) => {
                            Sentry.captureException(new Error(error));
                        }
                    );
            }
        } else {
            this.selectedCityId = null;
            this.selectedCityZip = this.details.billingZip = null;
            this.selectedCityName = this.details.billingCity = null;
            this.selectedStreet = this.details.billingStreet = null;
            this.details.billingHouseNumber = null;
            this.streets = [];
        }
    }

    protected streetInput(value): void {
        if (value) {
            this.streetError = false;
            this.selectedStreet = this.details.billingStreet = value.name
                ? value.name
                : value;
        } else {
            this.selectedStreet = this.details.billingStreet = null;
        }
    }

    protected async submit(): Promise<void> {
        this.errorInformation = [];
        this.successInformation = [];
        if (this.validationErrorsIsset()) {
            return;
        }

        if (!this.details.billingStreet && !this.showPostboxField) {
            this.streetError = true;
            return;
        }

        if (!this.details.billingHouseNumber && !this.showPostboxField) {
            this.houseNumberError = true;
            return;
        }

        if (authProvider.isAuthenticated()) {
            this.submitPending = true;
            this.details.contractId = this.$store.state.contracts.contractId;

            if (!this.showPostboxField) {
                this.details.postbox = '';
            }

            await tariffProvider.changeBillingData(this.details).then(
                (response) => {
                    this.submitPending = false;
                    if (response.data.success) {
                        this.originalCommunicationProfile =
                            this.details.communicationProfile ?? null;
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }

                        trackingProvider.changeBillingAddress();

                        this.$store.commit('tariff/SET_TARIFF_DETAILS', {
                            data: response.data,
                            contractId: this.$store.state.contracts.contractId,
                        });

                        this.mapData();
                    } else {
                        if (
                            response.data.errorMessages &&
                            response.data.errorMessages.length > 0
                        ) {
                            this.errorInformation = response.data.errorMessages;
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }
                    }

                    if (this.details.synchronizeWithCustomer) {
                        this.$store.dispatch('customer/getDetails');
                    }
                },
                (error) => {
                    this.submitPending = false;
                    this.successInformation = [];
                    this.errorInformation.push({
                        key: '',
                        message: this.$t('general.error').toLocaleString(),
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    }

    private validationErrorsIsset(): boolean {
        this.zipFailed = false;
        if (this.currentClient === 'wdenergy' && !this.details.billingEmail) {
            this.errorInformation.push({
                key: '',
                message: this.$t('widget.cs.invalidEmail').toLocaleString(),
            });
            return true;
        }

        if (this.currentClient === 'sachsenenergie') {
            const letters = /^[A-Za-zäöüÄÖÜß]+$/;

            if (
                this.details.billingFirstName.search(letters) === -1 ||
                this.details.billingSurname.search(letters) === -1
            ) {
                this.errorInformation.push({
                    key: '',
                    message: this.$t(
                        'widget.cbd.errorNameWithoutLetter'
                    ).toLocaleString(),
                });
                return true;
            }
        }

        if (this.showPostboxField && this.isPostboxError) {
            this.errorInformation.push({
                key: 'widget.cbd.postbox.error',
                message: this.$t('widget.cbd.postbox.error').toLocaleString(),
            });
            return true;
        }

        const regexp = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;
        if (
            this.displayElement('require-five-digits-zip') &&
            (this.details.billingZip.length !== 5 ||
                !regexp.test(this.details.billingZip))
        ) {
            this.zipFailed = true;
            return true;
        }

        return false;
    }
}
