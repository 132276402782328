import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { Datetime } from 'vue-datetime';
import moment from 'moment';
import WithRender from './meter-reading.html';
import { HeaderV2 } from '@/components/template/header-v2';
import { Footer } from '@/components/template/footer';
import { MessagesBlock } from '@/components/snippets/messages';
import { ShowMultipleErrorMessages } from '@/components/snippets/show-multiple-error-messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Meter from '@/interfaces/Meter';

@WithRender
@Component({
    components: {
        HeaderV2,
        datetime: Datetime,
        'app-footer': Footer,
        'messages-block': MessagesBlock,
        'show-multiple-error-messages': ShowMultipleErrorMessages,
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: 'description',
                    content: '',
                    template: () =>
                        `${
                            process.env.VUE_APP_META_DESCRIPTION
                                ? process.env.VUE_APP_META_DESCRIPTION
                                : ''
                        }`,
                },
            ],
        };
    },
})
export class MeterReading extends Base {
    public isSendData = false;
    protected errorInformation: Record<any, any> = [];
    protected successInformation: Record<any, any> = [];

    protected errors: any[] = [];

    private meter = IocContainer.get<Meter>(SERVICES.METER);

    private details: {
        contractId: number | null;
        counterNumber: number | string | null;
        reading: number | null;
        readAtDate: string;
        readAt: string;
    } = {
        contractId: null,
        counterNumber: null,
        reading: null,
        readAt: '',
        readAtDate: '',
    };

    protected elements = {
        bsw: ['login-footer-links-bsw', 'show-title'],
        ets: ['app-footer', 'header-v2'],
        velbert: ['login-footer-velbert'],
        wdenergy: ['app-footer-right'],
        'wdenergy-us': ['app-footer-right'],
        keppel: ['app-login-subtitle'],
        prokon: ['show-title'],
    };

    get locales(): string[] {
        return this.$i18n.availableLocales;
    }

    get loginLogo(): string {
        return (
            'background-image: url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.svg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.jpg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.png);'
        );
    }

    get loginAsideStyle(): string | false {
        switch (process.env.VUE_APP_ENVIRONMENT) {
            // No Image, but Centered
            case 'qcells':
                return false;

            default:
                // Image, not Centered
                return (
                    'background-image: url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.jpg), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.png), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.gif), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.svg); background-size: cover;'
                );
        }
    }

    get showMeterReadingForm(): boolean {
        return this.$store.state.settings.reportReadingWithoutLogin;
    }

    public mounted(): void {
        const query = this.$route.query;
        if (query.contractid) {
            this.details.contractId = parseInt(<string>query.contractid, 10);
        }
        if (query.counterid) {
            this.details.counterNumber = <string>query.counterid;
        }
        if (query.date) {
            this.details.readAtDate = <string>query.date;
        }
    }

    private getCustomErrorMessage(data: any): any {
        if (data.errors) {
            if (
                data.errors.reading_usage_sum_is_not_plausible &&
                data.errors.reading_usage_sum_is_not_plausible.length > 0
            ) {
                const firstError: any =
                    data.errors.reading_usage_sum_is_not_plausible[0];
                const contractReadingPlausability =
                    firstError.data &&
                    firstError.data.usage_slots &&
                    firstError.data.usage_slots.contract_reading_plausibility
                        ? firstError.data.usage_slots
                              .contract_reading_plausibility
                        : null;

                if (contractReadingPlausability !== null) {
                    const message = {
                        key: 'reading_usage_sum_is_not_plausible',
                        message: this.$t(
                            'meter.reading.error.usage.sum.not.plausible',
                            {
                                usage: parseInt(
                                    contractReadingPlausability.usage,
                                    10
                                ),
                                periodUsageStartDate: moment(
                                    contractReadingPlausability.periodUsageStartDate
                                )
                                    .locale('de')
                                    .format('D. MMMM Y'),
                                periodUsageEndDate: moment(
                                    contractReadingPlausability.periodUsageEndDate
                                )
                                    .locale('de')
                                    .format('D. MMMM Y'),
                                expectedUsage: parseInt(
                                    contractReadingPlausability.expectedUsage,
                                    10
                                ),
                            }
                        ),
                    };

                    return message;
                }
            }

            if (
                data.errors.exists_previous_reading_with_higher_value &&
                data.errors.exists_previous_reading_with_higher_value.length > 0
            ) {
                const firstError: any =
                    data.errors.exists_previous_reading_with_higher_value[0];
                const errorData: any = firstError.data ? firstError.data : null;

                if (errorData !== null) {
                    const message = {
                        key: 'exists_previous_reading_with_higher_value',
                        message: this.$t(
                            'meter.reading.error.previous.reading.with.higher.value',
                            {
                                value: errorData.value,
                                date: errorData.date,
                            }
                        ),
                    };

                    return message;
                }
            }

            if (
                data.errors.exists_future_reading_with_smaller_value &&
                data.errors.exists_future_reading_with_smaller_value.length > 0
            ) {
                const firstError: any =
                    data.errors.exists_future_reading_with_smaller_value[0];
                const errorData: any = firstError.data ? firstError.data : null;

                if (errorData !== null) {
                    const message = {
                        key: 'exists_future_reading_with_smaller_value',
                        message: this.$t(
                            'meter.reading.error.future.reading.with.smaller.value',
                            {
                                value: errorData.value,
                                date: errorData.date,
                            }
                        ),
                    };

                    return message;
                }
            }
        }

        return data.messageLocalized;
    }

    private sendMeterReading(): void {
        this.clearMessages();
        this.isSendData = true;
        if (this.details.readAtDate) {
            this.details.readAt = moment(this.details.readAtDate).format(
                'YYYY-MM-DD'
            );
        }
        this.meter
            .send(this.details)
            .then((response) => {
                if (response.data.success) {
                    if (typeof response.data.messageLocalized === 'object') {
                        this.successInformation.push(
                            response.data.messageLocalized
                        );
                    } else if (
                        typeof response.data.messageLocalized === 'string'
                    ) {
                        this.successInformation.push({
                            key: '',
                            message: response.data.messageLocalized,
                        });
                    }
                } else {
                    if (typeof response.data.messageLocalized === 'object') {
                        this.errorInformation.push(
                            this.getCustomErrorMessage(response.data)
                        );
                    } else if (
                        typeof response.data.messageLocalized === 'string'
                    ) {
                        this.errorInformation.push({
                            key: '',
                            message: response.data.messageLocalized,
                        });
                    }
                }
                this.isSendData = false;
            })
            .catch((error) => {
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.errors &&
                    Object.keys(error.response.data.errors).length > 0
                ) {
                    this.errors = Object.values(error.response.data.errors);
                }
                this.isSendData = false;
            });
    }

    private changeLocale(locale: string): void {
        this.$i18n.locale = locale;
        localStorage.setItem('wd-portal-language', this.$i18n.locale);
    }

    private clearMessages(): void {
        this.errors = [];
        this.successInformation = [];
        this.errorInformation = [];
    }
}
